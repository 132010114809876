.menu-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .menu-trigger {
    background: #ffffff;
    border-radius: 90px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    margin-right: auto; /* Strictly for positioning */
    height: 30px;
  }
  
  .menu-trigger:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }
  
  .menu-trigger span {
    font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0 10px;
  }
  
  .menu-trigger img {
    border-radius: 90px;
  }
  
  .menu {
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 60px;
    right: 0;
    width: 300px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transform: translateX(225px);
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li {
    border-bottom: 1px solid #dddddd;
  }
  
  .menu li a {
    text-decoration: none;
    color: #333333;
    padding: 15px 20px;
    display: block;
  }

  .uploadPlayers {
      color: #333333;
  }

  .csv-input {
      color: #1e4475;
  }
  
  .csv-label {
      color: #1e4475;
  }