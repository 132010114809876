.teamBox {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-size: calc(10px + 2vmin);
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  word-wrap: normal;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  cursor: pointer;
  vertical-align: text-center;
  text-align: center;
}