.App {
  text-align: center;
  height: 100vh;
  height: max-content;
  background-color: #282c34;
  color: white;
}

.App-logo {
  height: 20vmin;
  width: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  height: min-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

.loginFields {
  height: 10px;
  font-size: 10px;
}

.navBar {
    width: 100%;
    background-color: #111;
    color: white;
    padding: 10px;
}

.Export-content {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  /* height: 100vh; */
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.teamLink {
  vertical-align: text-top;
  font-size: 17px;
}


.taskBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}  
  
.menu-trigger {
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-right: auto; /* Strictly for positioning */
  height: 30px;
}

.Teams-content {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  flex-wrap: wrap;
  height: max-content;
  width: 95vw;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0;
  justify-content: center;
}