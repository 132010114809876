.App {
  text-align: center;
  height: 100vh;
  min-height: min-content;
  background-color: #282c34;
  color: white;
}

.App-logo {
  height: 20vmin;
  width: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  height: 100%;
  min-height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Players-header {
  padding-left: 10px;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Players {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.loginFields {
  height: 10px;
  font-size: 10px;
}

.navBar {
    width: 100%;
    background-color: #111;
    color: white;
    padding: 10px;
}

.Export-content {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.teamImage {
  height: auto;
  width: 50%;
}

.Players-content {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
